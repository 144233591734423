/* ------------------------------- faq ------------------------------- */

.faq {
  .faq__container {
    padding-top: 60px;
    padding-bottom: 60px;

    @include respond-to("md") {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .faq__items {
    display: flex;
    flex-flow: column wrap;
    height: 895px;

    @include respond-to("1330px") {
      height: 895px;
    }

    //@include respond-to("1235px") {
    //  height: 915px;
    //}
    //
    //@include respond-to("1145px") {
    //  height: 935px;
    //}
    //
    //@include respond-to("xl") {
    //  height: 970px;
    //}
    //
    //@include respond-to("1075px") {
    //  height: 990px;
    //}
    //
    //@include respond-to("1055px") {
    //  height: 1020px;
    //}
    //
    //@include respond-to("lg") {
    //  height: 1240px;
    //}
    //
    //@include respond-to("796px") {
    //  height: 1265px;
    //}

    @include respond-to("md") {
      flex-direction: column;
      height: auto;
    }
  }

  .faq__items::before,
  .faq__items::after {
    content: "";
    flex-basis: 100%;
    width: 0;
    order: 2;
  }

  .faq__item {
    width: 49.5%;
    margin-bottom: 10px;

    @include respond-to("md") {
      width: 100%;
    }
  }

  .faq__item:nth-child(-n + 5) {
    order: 1;
    margin-right: 10px;
  }

  .faq__item:nth-child(n + 6):nth-child(-n + 10) {
    order: 2;
  }

  .faq__row {
    display: flex;
    gap: 10px;
    min-height: 550px;

    @include respond-to("lg") {
      flex-direction: column;
      min-height: initial;
    }
  }

  .faq__col {
    width: 33%;
    height: 100%;

    @include respond-to("lg") {
      width: 100%;
    }
  }
}

/* ------------------------------- main ------------------------------- */

.main {
  background: linear-gradient(
      180deg,
      rgba(#f1f5f933, 0.2) 8.25%,
      rgba(#9fa4a733, 0.2) 100%
    ),
    linear-gradient(0deg, $white 0%, $white 100%), $grey-black;
  border-bottom: 1px solid rgba($black, 0.05);

  .main__container {
    padding-top: 60px;
    padding-bottom: 60px;

    @include respond-to("md") {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .main__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 60px;

    @include respond-to("md") {
      align-items: flex-start;
      gap: 20px;
      margin-bottom: 40px;
    }

    .preloader {
      width: 700px;
      height: 75px;

      @include respond-to("md") {
        width: 98%;
      }

      @include respond-to("sm") {
        width: 100%;
      }
    }
  }

  .main__title {
    font-weight: 500;
  }

  .main__description {
  }

  .main__items {
    display: flex;
    gap: 20px;

    @include respond-to("md") {
      flex-direction: column;
    }

    @include respond-to("sm") {
      gap: 40px;
    }

    &.mobile {
      @include respond-to("sm") {
        gap: 10px;
      }
    }
  }

  .main__item-left {
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 20px;

    @include respond-to("431px") {
      gap: 10px;
    }
  }

  .main__item-right {
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 20px;

    @include respond-to("431px") {
      gap: 10px;
    }
  }

  .main__item-wrapper {
    display: flex;
    gap: 20px;

    @include respond-to("md") {
      flex-direction: column;
    }

    @include respond-to("431px") {
      gap: 10px;
    }
  }

  .main__item {
    img {
      max-width: 426px;
      width: 100%;
      max-height: 426px;
      height: 100%;

      border-radius: 14px;
      object-fit: cover;

      @include respond-to("md") {
        max-width: none;
        max-height: none;
      }
    }
  }

  .main__btn {
    position: relative;

    margin-top: 20px;

    text-align: center;

    @include respond-to("md") {
      margin-top: 10px;
    }

    button {
      max-width: 220px;
      width: 100%;

      @include respond-to("md") {
        max-width: none;
      }
    }
  }

  .main__wallet {
    display: none;

    &.active {
      position: absolute;
      top: 62px;
      right: 0;
      left: 0;

      display: block;
      width: 207px;
      margin: auto;

      text-align: left;
    }
  }

  .main__nfts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .main__nfts > * {
    flex-basis: calc(25% - 15px);

    @include respond-to("lg") {
      flex-basis: calc(33% - 11px);
    }

    @include respond-to("md") {
      flex-basis: calc(50% - 10px);
    }

    @include respond-to("sm") {
      flex-basis: 100%;
    }
  }

  .main__nft {
  }

  .main__tokens {
    display: flex;
    gap: 20px;

    @include respond-to("lg") {
      flex-direction: column;
      align-items: center;
    }
  }

  .main__token-left {
    position: relative;

    max-width: 650px;
    width: 100%;
    height: 606px;

    border-radius: 14px;
    background-color: $light-green;

    overflow: hidden;

    @include respond-to("md") {
      height: 410px;
    }

    @include respond-to("sm") {
      height: 320px;
    }
  }

  .main__token-right {
    position: relative;

    max-width: 650px;
    width: 100%;
    height: 606px;

    border-radius: 14px;
    background-color: $violet;

    overflow: hidden;

    @include respond-to("md") {
      height: 410px;
    }

    @include respond-to("sm") {
      height: 320px;
    }
  }
}

/* ------------------------------- finish ------------------------------- */

.finish {
  .finish__container {
  }

  .finish__main {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 84px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    max-width: 708px;
    width: 100%;
    margin: auto;

    @include respond-to("md") {
      max-width: 410px;
    }

    @include respond-to("sm") {
      gap: 30px;
    }

    .desktop {
      display: block;

      @include respond-to("sm") {
        display: none;
      }
    }

    .mobile {
      display: none;

      @include respond-to("sm") {
        display: block;
      }
    }
  }

  .finish__content {
  }

  .finish__description {
    margin-top: 10px;

    font-weight: 700;
    text-align: center;

    color: $light-green;
  }

  .finish__title {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 102%;
    letter-spacing: -1.44px;
    text-align: center;

    color: $white;

    @include respond-to("sm") {
      max-width: 285px;
      width: 100%;

      font-size: 24px;
      letter-spacing: -0.96px;
    }
  }

  .finish__percent {
    padding: 15px 30px;

    color: $white;
    border-radius: 45px;
    background: rgba($white, 0.2);

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
    letter-spacing: -0.8px;

    @include respond-to("sm") {
      max-width: 296px;
      width: 100%;
    }
  }

  &.burning {
    position: relative;

    background-image: url("/assets/images/burning/burning.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 10%;
    background-position-x: center;

    .finish__main {
      @include respond-to("md") {
        bottom: 40px;
      }
    }
  }

  &.empty {
    position: relative;

    background-image: url("/assets/images/empty/empty.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 20%;
    background-position-x: center;

    @include respond-to("md") {
      background-position-x: 70%;
    }

    .finish__main {
      top: 80px;
      bottom: initial;

      @include respond-to("md") {
        top: 40px;
      }
    }
  }

  &.success {
    position: relative;

    background-image: url("/assets/images/success/success.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 10%;
    background-position-x: center;

    .finish__main {
      max-width: 1010px;

      @include respond-to("lg") {
        max-width: 768px;
        padding: 0 20px;
      }

      @include respond-to("md") {
        bottom: 40px;

        max-width: 450px;
      }

      @include respond-to("sm") {
        align-items: flex-start;
      }

      button {
        @include respond-to("sm") {
          width: 100%;
          padding: 15px;
        }
      }
    }

    .finish__title {
      @include respond-to("sm") {
        max-width: 450px;

        text-align: left;
      }
    }

    .finish__description {
      @include respond-to("sm") {
        text-align: left;
      }
    }
  }
}

/* ------------------------------- terms ------------------------------- */

.terms {
  .terms__container {
    padding-top: 50px;
    padding-bottom: 50px;

    @include respond-to("md") {
      padding-top: 40px;
    }
  }

  h1,
  h2,
  p {
    margin-bottom: 15px;
  }

  h2 {
    font-size: 38px;
  }

  p {
    line-height: 22px;
  }
}
