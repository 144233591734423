/* ------------------------------- header ------------------------------- */

.header {
  border-bottom: 1px solid rgba($black, 0.05);

  .header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-top: 18px;
    padding-bottom: 17px;

    @include respond-to("450px") {
      //padding-top: 32px;
      //padding-bottom: 30px;
    }
  }

  .header__logo {
  }

  .header__address {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
    letter-spacing: -0.8px;

    color: rgba($black, 0.2);

    @include respond-to("md") {
      display: none;
    }
  }

  .header__btn-group {
    display: flex;
    gap: 8px;
  }

  .header__btn {
    position: relative;
  }

  .header__claim {
    display: none;

    &.active {
      position: absolute;
      top: 52px;
      right: -30px;
      z-index: 5;

      display: block;
      width: 225px;
    }
  }

  .header__wallet {
    display: none;

    &.active {
      position: absolute;
      top: 52px;
      right: 0;

      display: block;
      width: 207px;
    }
  }

  .header__btn-primary {
  }

  .header__btn-logout {
  }
}
