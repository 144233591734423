/* ------------------------------- preloader ------------------------------- */

.preloader {
  position: relative;

  border-radius: 14px;
  background-color: $grey-black;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";

    width: 100%;
    height: 100%;

    background: rgba($grey-semi, 0.4);

    animation: pulse 1.4s infinite alternate ease-in-out;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
