/* ------------------------------- footer ------------------------------- */

.footer {
  padding-top: 30px;
  padding-bottom: 30px;

  background-color: $black;

  @include respond-to("md") {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer__container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 20px;

    @include respond-to("md") {
      grid-template-columns: none;
      grid-template-rows: auto;
      justify-items: center;
    }
  }

  .footer__logo {
  }

  .footer__copy,
  .footer__link {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    color: $white;
  }

  .footer__copy {
    text-align: center;
  }

  .footer__link {
    text-decoration-line: underline;

    transition: all 0.2s ease-in-out;

    &:hover {
      color: $light-green;
    }
  }
}
