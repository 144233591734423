/* ------------------------------- card-faq ------------------------------- */

.card-faq {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 40px;

  background: $grey;
  border-radius: 14px;

  cursor: pointer;
  overflow: hidden;

  .card-faq__content {
  }

  .card-faq__title {
  }

  .card-faq__text {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    color: $white;
  }

  .card-faq__add {
    display: block;
  }

  .card-faq__close {
    display: none;
  }

  //transition: all 0.1s ease-in-out;

  &.open {
    background-color: $black;

    //transition: all 0.1s ease-in-out;

    h3 {
      color: $light-green;
    }

    .card-faq__add {
      display: none;
    }

    .card-faq__close {
      display: block;
    }
  }

  .card-faq__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;

    list-style-type: none;

    li {
      position: relative;

      padding-left: 39px;

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      color: $white;

      list-style: none;
    }

    li:before {
      position: absolute;
      top: 25%;
      left: 0;
      content: "";

      width: 24px;
      height: 24px;

      background-image: url("/assets/images/icons/check-circle-linear.svg");
      background-size: cover;
    }
  }

  a {
    color: $light-green;

    &:hover {
      text-decoration: underline;
    }
  }
}

/* ------------------------------- card-nft ------------------------------- */

.card-nft {
  position: relative;

  .card-nft__image {
    img {
      max-width: 315px;
      width: 100%;
      max-height: 315px;
      height: 100%;

      border-radius: 14px;
      object-fit: cover;

      @include respond-to("md") {
        max-width: 354px;
        max-height: 354px;
      }

      @include respond-to("sm") {
        max-width: 438px;
        max-height: 438px;
      }
    }
  }

  .card-nft__items {
    position: absolute;
    top: 10px;
    left: 10px;

    display: flex;
    gap: 10px;
  }

  .card-nft__item {
    display: flex;
    padding: 4px 8px 4px 4px;
    align-items: center;
    gap: 4px;

    border-radius: 30px;
    background: rgba($white, 0.2);
    backdrop-filter: blur(20px);
  }

  .card-nft__icon {
  }

  .card-nft__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
    letter-spacing: -0.48px;

    color: $white;
  }

  .card-nft__preloader {
    max-width: 315px;
    width: 100%;
    height: 315px;

    @include respond-to("1300px") {
      max-width: 290px;
      height: 290px;
    }

    @include respond-to("1180px") {
      max-width: 260px;
      height: 260px;
    }

    @include respond-to("xl") {
      max-width: 235px;
      height: 235px;
    }

    @include respond-to("lg") {
      max-width: 230px;
      height: 230px;
    }

    @include respond-to("md") {
      max-width: 205px;
      height: 205px;
    }

    @include respond-to("sm") {
      max-width: 438px;
      height: 330px;
      width: 100%;
    }

    @include respond-to("xs") {
      max-width: 315px;
      height: 315px;
    }
  }
}

/* ------------------------------- card-token ------------------------------- */

.card-token {
  .card-token__big-cyclr {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    max-width: 569px;
    width: 100%;
    margin: auto;

    @include respond-to("xs") {
      top: -87px;
      left: -72px;

      width: 460px;
    }
  }

  .card-token__small-cyclr {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    max-width: 376px;
    width: 100%;
    margin: auto;

    @include respond-to("md") {
      max-width: 242px;
    }

    @include respond-to("xs") {
      top: -90px;

      max-width: 225px;
    }
  }

  .card-token__big-shift {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    max-width: 571px;
    width: 100%;
    margin: auto;

    @include respond-to("xs") {
      top: -74px;
      left: -30px;

      width: 380px;
    }
  }

  .card-token__small-shift {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    max-width: 376px;
    width: 100%;
    margin: auto;

    @include respond-to("md") {
      max-width: 242px;
    }

    @include respond-to("xs") {
      top: -75px;

      max-width: 225px;
    }
  }

  .card-token__btn {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 105px;

    display: flex;
    padding: 10px 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    max-width: 207px;
    margin: auto;

    border-radius: 30px;
    background: $white;

    @include respond-to("md") {
      bottom: 40px;
    }

    span:first-child {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 102%;
      letter-spacing: -0.64px;
    }

    span:last-child {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 102%;
      letter-spacing: -0.48px;

      color: rgba($black, 0.3);
    }

    &.red {
      max-width: 225px;

      color: $white;
      background: $red;

      span:first-child {
      }

      span:last-child {
        color: rgba($white, 0.6);
      }
    }
  }
}
