/* ---------------------------------- Base ---------------------------------- */
/* ---------------------------------- Text ---------------------------------- */
/* --------------------------------- Background -------------------------------- */
/* --------------------------------- Buttons -------------------------------- */
/* ---------------------------------- Cards --------------------------------- */
/* --------------------------------- Inputs --------------------------------- */
/* ---------------------------------- Utils --------------------------------- */
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  background-color: #ffffff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

/* ----------------------------- Work-Sans ----------------------------- */
@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Work-Sans/WorkSans-Regular.ttf");
}
@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/Work-Sans/WorkSans-Medium.ttf");
}
@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/Work-Sans/WorkSans-SemiBold.ttf");
}
@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/Work-Sans/WorkSans-Bold.ttf");
}
/**
 * Basic typography style for text
 */
body {
  color: #000000;
  font: normal 100%/1.2 "Work-Sans", "Arial", sans-serif;
  letter-spacing: normal;
}

button {
  font-family: "Work-Sans", "Arial", sans-serif;
}

.title {
  font-size: 48px;
  font-style: normal;
  line-height: 102%;
  letter-spacing: -1.92px;
}

.subtitle {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.text {
  font-size: 18px;
  font-style: normal;
  line-height: 102%;
  letter-spacing: -0.72px;
}

.container {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
@media (max-width: 1120px) {
  .container {
    max-width: 1040px;
  }
}
@media (max-width: 1040px) {
  .container {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 450px;
  }
}

/* ------------------------------- button ------------------------------- */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.8px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.button .button__loader {
  position: relative;
  width: 1em;
  height: 1em;
  margin-right: 15px;
  margin-left: 25px;
  color: #b5ff23;
  border-radius: 50%;
  font-size: 4px;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

/* ------------------------------- button--primary ------------------------------- */
.button--primary {
  position: relative;
  gap: 10px;
  height: 50px;
  padding: 15px 30px 15px 20px;
  color: #000000;
  background-color: #b5ff23;
  border-radius: 85px;
}
.button--primary:hover {
  box-shadow: 0 3px 12px #b5ff23;
}
.button--primary .button__arrow {
  display: none;
}
.button--primary .button__arrow.active {
  position: absolute;
  bottom: -2px;
  display: block;
  margin: auto;
}
.button--primary .desktop {
  display: block;
}
@media (max-width: 450px) {
  .button--primary .desktop {
    display: none;
  }
}
.button--primary .mobile {
  display: none;
}
@media (max-width: 450px) {
  .button--primary .mobile {
    display: block;
  }
}

/* ------------------------------- button--secondary ------------------------------- */
.button--secondary {
  position: relative;
  gap: 10px;
  height: 60px;
  padding: 20px 30px;
  color: #000000;
  background-color: #b5ff23;
  border-radius: 14px;
}
.button--secondary:hover {
  box-shadow: 0 3px 12px #b5ff23;
}
.button--secondary .button__arrow {
  display: none;
}
.button--secondary .button__arrow.active {
  position: absolute;
  bottom: -2px;
  display: block;
  margin: auto;
}

/* ------------------------------- button--logout ------------------------------- */
.button--logout {
  gap: 10px;
  height: 50px;
  padding: 15px 20px;
  color: #000000;
  background-color: transparent;
  border-radius: 85px;
}
@media (max-width: 768px) {
  .button--logout {
    height: 40px;
    padding: 10px 10px 13px;
  }
}
.button--logout:hover {
  background-color: #f5f5f5;
}
@media (max-width: 332px) {
  .button--logout .button--logout-mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .button--logout .button--logout-hidden {
    display: none;
  }
}

/* ------------------------------- button--accent ------------------------------- */
.button--accent {
  height: 50px;
  padding: 15px 30px;
  color: #000000;
  background-color: #b5ff23;
  border-radius: 45px;
}
.button--accent:hover {
  box-shadow: 0 3px 12px #b5ff23;
}

/* ------------------------------- button--claim ------------------------------- */
.button--claim {
  position: relative;
  height: 50px;
  padding: 15px 20px;
  color: #000000;
  background-color: #b5ff23;
  border-radius: 45px;
}
@media (max-width: 768px) {
  .button--claim {
    height: 40px;
    padding: 10px 20px;
  }
}
.button--claim:hover {
  box-shadow: 0 3px 12px #b5ff23;
}
.button--claim .button__arrow {
  display: none;
}
.button--claim .button__arrow.active {
  position: absolute;
  bottom: -2px;
  display: block;
  margin: auto;
}
.button--claim .desktop {
  display: block;
}
@media (max-width: 768px) {
  .button--claim .desktop {
    display: none;
  }
}
.button--claim .mobile {
  display: none;
}
@media (max-width: 768px) {
  .button--claim .mobile {
    display: block;
  }
}

/* ------------------------------- card-faq ------------------------------- */
.card-faq {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 40px;
  background: #f5f5f5;
  border-radius: 14px;
  cursor: pointer;
  overflow: hidden;
}
.card-faq .card-faq__text {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
}
.card-faq .card-faq__add {
  display: block;
}
.card-faq .card-faq__close {
  display: none;
}
.card-faq.open {
  background-color: #000000;
}
.card-faq.open h3 {
  color: #b5ff23;
}
.card-faq.open .card-faq__add {
  display: none;
}
.card-faq.open .card-faq__close {
  display: block;
}
.card-faq .card-faq__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  list-style-type: none;
}
.card-faq .card-faq__list li {
  position: relative;
  padding-left: 39px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  list-style: none;
}
.card-faq .card-faq__list li:before {
  position: absolute;
  top: 25%;
  left: 0;
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("/assets/images/icons/check-circle-linear.svg");
  background-size: cover;
}
.card-faq a {
  color: #b5ff23;
}
.card-faq a:hover {
  text-decoration: underline;
}

/* ------------------------------- card-nft ------------------------------- */
.card-nft {
  position: relative;
}
.card-nft .card-nft__image img {
  max-width: 315px;
  width: 100%;
  max-height: 315px;
  height: 100%;
  border-radius: 14px;
  object-fit: cover;
}
@media (max-width: 768px) {
  .card-nft .card-nft__image img {
    max-width: 354px;
    max-height: 354px;
  }
}
@media (max-width: 450px) {
  .card-nft .card-nft__image img {
    max-width: 438px;
    max-height: 438px;
  }
}
.card-nft .card-nft__items {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
}
.card-nft .card-nft__item {
  display: flex;
  padding: 4px 8px 4px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
}
.card-nft .card-nft__text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.48px;
  color: #ffffff;
}
.card-nft .card-nft__preloader {
  max-width: 315px;
  width: 100%;
  height: 315px;
}
@media (max-width: 1300px) {
  .card-nft .card-nft__preloader {
    max-width: 290px;
    height: 290px;
  }
}
@media (max-width: 1180px) {
  .card-nft .card-nft__preloader {
    max-width: 260px;
    height: 260px;
  }
}
@media (max-width: 1120px) {
  .card-nft .card-nft__preloader {
    max-width: 235px;
    height: 235px;
  }
}
@media (max-width: 1040px) {
  .card-nft .card-nft__preloader {
    max-width: 230px;
    height: 230px;
  }
}
@media (max-width: 768px) {
  .card-nft .card-nft__preloader {
    max-width: 205px;
    height: 205px;
  }
}
@media (max-width: 450px) {
  .card-nft .card-nft__preloader {
    max-width: 438px;
    height: 330px;
    width: 100%;
  }
}
@media (max-width: 360px) {
  .card-nft .card-nft__preloader {
    max-width: 315px;
    height: 315px;
  }
}

/* ------------------------------- card-token ------------------------------- */
.card-token .card-token__big-cyclr {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  max-width: 569px;
  width: 100%;
  margin: auto;
}
@media (max-width: 360px) {
  .card-token .card-token__big-cyclr {
    top: -87px;
    left: -72px;
    width: 460px;
  }
}
.card-token .card-token__small-cyclr {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  max-width: 376px;
  width: 100%;
  margin: auto;
}
@media (max-width: 768px) {
  .card-token .card-token__small-cyclr {
    max-width: 242px;
  }
}
@media (max-width: 360px) {
  .card-token .card-token__small-cyclr {
    top: -90px;
    max-width: 225px;
  }
}
.card-token .card-token__big-shift {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  max-width: 571px;
  width: 100%;
  margin: auto;
}
@media (max-width: 360px) {
  .card-token .card-token__big-shift {
    top: -74px;
    left: -30px;
    width: 380px;
  }
}
.card-token .card-token__small-shift {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  max-width: 376px;
  width: 100%;
  margin: auto;
}
@media (max-width: 768px) {
  .card-token .card-token__small-shift {
    max-width: 242px;
  }
}
@media (max-width: 360px) {
  .card-token .card-token__small-shift {
    top: -75px;
    max-width: 225px;
  }
}
.card-token .card-token__btn {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 105px;
  display: flex;
  padding: 10px 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  max-width: 207px;
  margin: auto;
  border-radius: 30px;
  background: #ffffff;
}
@media (max-width: 768px) {
  .card-token .card-token__btn {
    bottom: 40px;
  }
}
.card-token .card-token__btn span:first-child {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.64px;
}
.card-token .card-token__btn span:last-child {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.48px;
  color: rgba(0, 0, 0, 0.3);
}
.card-token .card-token__btn.red {
  max-width: 225px;
  color: #ffffff;
  background: #ff4646;
}
.card-token .card-token__btn.red span:last-child {
  color: rgba(255, 255, 255, 0.6);
}

/* ------------------------------- info-snack-bar ------------------------------- */
/* ------------------------------- error-snack-bar ------------------------------- */
.error-snack-bar .snack-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.error-snack-bar .snack-bar p, .error-snack-bar .snack-bar a {
  font-family: "Work-Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #ffffff;
}
.error-snack-bar .snack-bar__close {
  cursor: pointer;
}

/* ------------------------------- success-snack-bar ------------------------------- */
.success-snack-bar .snack-bar {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

/* ------------------------------- preloader ------------------------------- */
.preloader {
  position: relative;
  border-radius: 14px;
  background-color: #eeeeee;
  overflow: hidden;
}
.preloader::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(90, 90, 90, 0.4);
  animation: pulse 1.4s infinite alternate ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/* ------------------------------- faq ------------------------------- */
.faq .faq__container {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 768px) {
  .faq .faq__container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.faq .faq__items {
  display: flex;
  flex-flow: column wrap;
  height: 895px;
}
@media (max-width: 1330px) {
  .faq .faq__items {
    height: 895px;
  }
}
@media (max-width: 768px) {
  .faq .faq__items {
    flex-direction: column;
    height: auto;
  }
}
.faq .faq__items::before,
.faq .faq__items::after {
  content: "";
  flex-basis: 100%;
  width: 0;
  order: 2;
}
.faq .faq__item {
  width: 49.5%;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .faq .faq__item {
    width: 100%;
  }
}
.faq .faq__item:nth-child(-n+5) {
  order: 1;
  margin-right: 10px;
}
.faq .faq__item:nth-child(n+6):nth-child(-n+10) {
  order: 2;
}
.faq .faq__row {
  display: flex;
  gap: 10px;
  min-height: 550px;
}
@media (max-width: 1040px) {
  .faq .faq__row {
    flex-direction: column;
    min-height: initial;
  }
}
.faq .faq__col {
  width: 33%;
  height: 100%;
}
@media (max-width: 1040px) {
  .faq .faq__col {
    width: 100%;
  }
}

/* ------------------------------- main ------------------------------- */
.main {
  background: linear-gradient(180deg, rgba(241, 245, 249, 0.2) 8.25%, rgba(159, 164, 167, 0.2) 100%), linear-gradient(0deg, #ffffff 0%, #ffffff 100%), #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.main .main__container {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 768px) {
  .main .main__container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.main .main__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .main .main__header {
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 40px;
  }
}
.main .main__header .preloader {
  width: 700px;
  height: 75px;
}
@media (max-width: 768px) {
  .main .main__header .preloader {
    width: 98%;
  }
}
@media (max-width: 450px) {
  .main .main__header .preloader {
    width: 100%;
  }
}
.main .main__title {
  font-weight: 500;
}
.main .main__items {
  display: flex;
  gap: 20px;
}
@media (max-width: 768px) {
  .main .main__items {
    flex-direction: column;
  }
}
@media (max-width: 450px) {
  .main .main__items {
    gap: 40px;
  }
}
@media (max-width: 450px) {
  .main .main__items.mobile {
    gap: 10px;
  }
}
.main .main__item-left {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 20px;
}
@media (max-width: 431px) {
  .main .main__item-left {
    gap: 10px;
  }
}
.main .main__item-right {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 20px;
}
@media (max-width: 431px) {
  .main .main__item-right {
    gap: 10px;
  }
}
.main .main__item-wrapper {
  display: flex;
  gap: 20px;
}
@media (max-width: 768px) {
  .main .main__item-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 431px) {
  .main .main__item-wrapper {
    gap: 10px;
  }
}
.main .main__item img {
  max-width: 426px;
  width: 100%;
  max-height: 426px;
  height: 100%;
  border-radius: 14px;
  object-fit: cover;
}
@media (max-width: 768px) {
  .main .main__item img {
    max-width: none;
    max-height: none;
  }
}
.main .main__btn {
  position: relative;
  margin-top: 20px;
  text-align: center;
}
@media (max-width: 768px) {
  .main .main__btn {
    margin-top: 10px;
  }
}
.main .main__btn button {
  max-width: 220px;
  width: 100%;
}
@media (max-width: 768px) {
  .main .main__btn button {
    max-width: none;
  }
}
.main .main__wallet {
  display: none;
}
.main .main__wallet.active {
  position: absolute;
  top: 62px;
  right: 0;
  left: 0;
  display: block;
  width: 207px;
  margin: auto;
  text-align: left;
}
.main .main__nfts {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.main .main__nfts > * {
  flex-basis: calc(25% - 15px);
}
@media (max-width: 1040px) {
  .main .main__nfts > * {
    flex-basis: calc(33% - 11px);
  }
}
@media (max-width: 768px) {
  .main .main__nfts > * {
    flex-basis: calc(50% - 10px);
  }
}
@media (max-width: 450px) {
  .main .main__nfts > * {
    flex-basis: 100%;
  }
}
.main .main__tokens {
  display: flex;
  gap: 20px;
}
@media (max-width: 1040px) {
  .main .main__tokens {
    flex-direction: column;
    align-items: center;
  }
}
.main .main__token-left {
  position: relative;
  max-width: 650px;
  width: 100%;
  height: 606px;
  border-radius: 14px;
  background-color: #b5ff23;
  overflow: hidden;
}
@media (max-width: 768px) {
  .main .main__token-left {
    height: 410px;
  }
}
@media (max-width: 450px) {
  .main .main__token-left {
    height: 320px;
  }
}
.main .main__token-right {
  position: relative;
  max-width: 650px;
  width: 100%;
  height: 606px;
  border-radius: 14px;
  background-color: #854bff;
  overflow: hidden;
}
@media (max-width: 768px) {
  .main .main__token-right {
    height: 410px;
  }
}
@media (max-width: 450px) {
  .main .main__token-right {
    height: 320px;
  }
}

/* ------------------------------- finish ------------------------------- */
.finish .finish__main {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  max-width: 708px;
  width: 100%;
  margin: auto;
}
@media (max-width: 768px) {
  .finish .finish__main {
    max-width: 410px;
  }
}
@media (max-width: 450px) {
  .finish .finish__main {
    gap: 30px;
  }
}
.finish .finish__main .desktop {
  display: block;
}
@media (max-width: 450px) {
  .finish .finish__main .desktop {
    display: none;
  }
}
.finish .finish__main .mobile {
  display: none;
}
@media (max-width: 450px) {
  .finish .finish__main .mobile {
    display: block;
  }
}
.finish .finish__description {
  margin-top: 10px;
  font-weight: 700;
  text-align: center;
  color: #b5ff23;
}
.finish .finish__title {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 102%;
  letter-spacing: -1.44px;
  text-align: center;
  color: #ffffff;
}
@media (max-width: 450px) {
  .finish .finish__title {
    max-width: 285px;
    width: 100%;
    font-size: 24px;
    letter-spacing: -0.96px;
  }
}
.finish .finish__percent {
  padding: 15px 30px;
  color: #ffffff;
  border-radius: 45px;
  background: rgba(255, 255, 255, 0.2);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.8px;
}
@media (max-width: 450px) {
  .finish .finish__percent {
    max-width: 296px;
    width: 100%;
  }
}
.finish.burning {
  position: relative;
  background-image: url("/assets/images/burning/burning.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 10%;
  background-position-x: center;
}
@media (max-width: 768px) {
  .finish.burning .finish__main {
    bottom: 40px;
  }
}
.finish.empty {
  position: relative;
  background-image: url("/assets/images/empty/empty.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 20%;
  background-position-x: center;
}
@media (max-width: 768px) {
  .finish.empty {
    background-position-x: 70%;
  }
}
.finish.empty .finish__main {
  top: 80px;
  bottom: initial;
}
@media (max-width: 768px) {
  .finish.empty .finish__main {
    top: 40px;
  }
}
.finish.success {
  position: relative;
  background-image: url("/assets/images/success/success.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 10%;
  background-position-x: center;
}
.finish.success .finish__main {
  max-width: 1010px;
}
@media (max-width: 1040px) {
  .finish.success .finish__main {
    max-width: 768px;
    padding: 0 20px;
  }
}
@media (max-width: 768px) {
  .finish.success .finish__main {
    bottom: 40px;
    max-width: 450px;
  }
}
@media (max-width: 450px) {
  .finish.success .finish__main {
    align-items: flex-start;
  }
}
@media (max-width: 450px) {
  .finish.success .finish__main button {
    width: 100%;
    padding: 15px;
  }
}
@media (max-width: 450px) {
  .finish.success .finish__title {
    max-width: 450px;
    text-align: left;
  }
}
@media (max-width: 450px) {
  .finish.success .finish__description {
    text-align: left;
  }
}

/* ------------------------------- terms ------------------------------- */
.terms .terms__container {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .terms .terms__container {
    padding-top: 40px;
  }
}
.terms h1,
.terms h2,
.terms p {
  margin-bottom: 15px;
}
.terms h2 {
  font-size: 38px;
}
.terms p {
  line-height: 22px;
}

/* ------------------------------- wrapper ------------------------------- */
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wrapper .wrapper__main {
  flex: 1 1 auto;
}
/* ------------------------------- wallet ------------------------------- */
.wallet {
  padding: 4px;
  border-radius: 16px;
  background: #f5f5f5;
}
.wallet .wallet__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  border-radius: 12px;
  background: #ffffff;
}
.wallet .wallet__item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px 10px 10px;
  cursor: pointer;
}
.wallet .wallet__item:hover {
  border-radius: 12px;
  background: #f5f5f5;
}
.wallet .wallet__item-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 15px 10px;
}
.wallet .wallet__icon {
  width: 32px;
}
.wallet .wallet__content {
  display: flex;
  flex-direction: column;
  gap: 2px;
  word-break: break-all;
}
.wallet .wallet__title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.64px;
}
.wallet .wallet__description {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.48px;
  color: rgba(0, 0, 0, 0.3);
}
.wallet .wallet__description.red {
  color: #ff4646;
}
.wallet .wallet__description.black {
  color: #000000;
}
.wallet .wallet__btn {
  width: 100%;
}
.wallet .wallet__btn button {
  height: 40px;
  width: 100%;
  padding: 12px 18px;
  font-size: 16px;
  letter-spacing: -0.64px;
}

/* ------------------------------- header ------------------------------- */
.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.header .header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-top: 18px;
  padding-bottom: 17px;
}
.header .header__address {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: -0.8px;
  color: rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .header .header__address {
    display: none;
  }
}
.header .header__btn-group {
  display: flex;
  gap: 8px;
}
.header .header__btn {
  position: relative;
}
.header .header__claim {
  display: none;
}
.header .header__claim.active {
  position: absolute;
  top: 52px;
  right: -30px;
  z-index: 5;
  display: block;
  width: 225px;
}
.header .header__wallet {
  display: none;
}
.header .header__wallet.active {
  position: absolute;
  top: 52px;
  right: 0;
  display: block;
  width: 207px;
}
/* ------------------------------- footer ------------------------------- */
.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #000000;
}
@media (max-width: 768px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.footer .footer__container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 20px;
}
@media (max-width: 768px) {
  .footer .footer__container {
    grid-template-columns: none;
    grid-template-rows: auto;
    justify-items: center;
  }
}
.footer .footer__copy,
.footer .footer__link {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
}
.footer .footer__copy {
  text-align: center;
}
.footer .footer__link {
  text-decoration-line: underline;
  transition: all 0.2s ease-in-out;
}
.footer .footer__link:hover {
  color: #b5ff23;
}