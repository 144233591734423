// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.container {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @include respond-to("xl") {
    max-width: 1040px;
  }

  @include respond-to("lg") {
    max-width: 768px;
  }

  @include respond-to("md") {
    max-width: 450px;
  }
}
