// -----------------------------------------------------------------------------
// Common block styles
// -----------------------------------------------------------------------------

/* ------------------------------- wrapper ------------------------------- */

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .wrapper__header {
  }

  .wrapper__main {
    flex: 1 1 auto;
  }

  .wrapper__footer {
  }
}

/* ------------------------------- wallet ------------------------------- */

.wallet {
  padding: 4px;

  border-radius: 16px;
  background: $grey;

  .wallet__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;

    border-radius: 12px;
    background: $white;
  }

  .wallet__item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px 10px 10px;

    cursor: pointer;

    &:hover {
      border-radius: 12px;
      background: $grey;
    }
  }

  .wallet__item-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 15px 10px;
  }

  .wallet__icon {
    width: 32px;
  }

  .wallet__content {
    display: flex;
    flex-direction: column;
    gap: 2px;

    word-break: break-all;
  }

  .wallet__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
    letter-spacing: -0.64px;
  }

  .wallet__description {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
    letter-spacing: -0.48px;

    color: rgba($black, 0.3);

    &.red {
      color: $red;
    }

    &.black {
      color: $black;
    }
  }

  .wallet__btn {
    width: 100%;

    button {
      height: 40px;
      width: 100%;
      padding: 12px 18px;

      font-size: 16px;
      letter-spacing: -0.64px;
    }
  }
}
