/**
 * Basic typography style for text
 */

body {
  color: $text-main;
  font: normal 100% / 1.2 $text-font-stack;
  letter-spacing: normal;
}

button {
  font-family: $text-font-stack;
}

.title {
  font-size: 48px;
  font-style: normal;
  line-height: 102%;
  letter-spacing: -1.92px;
}

.subtitle {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.text {
  font-size: 18px;
  font-style: normal;
  line-height: 102%;
  letter-spacing: -0.72px;
}
