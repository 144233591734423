// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/* ----------------------------- Work-Sans ----------------------------- */

@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Work-Sans/WorkSans-Regular.ttf");
}

@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/Work-Sans/WorkSans-Medium.ttf");
}

@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/Work-Sans/WorkSans-SemiBold.ttf");
}

@font-face {
  font-family: "Work-Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/Work-Sans/WorkSans-Bold.ttf");
}
