/* ------------------------------- info-snack-bar ------------------------------- */

.info-snack-bar {
  .snack-bar {
    //p {
    //  font-family: "Plus Jakarta Sans", sans-serif;
    //  font-size: 16px;
    //  font-style: normal;
    //  font-weight: 500;
    //  line-height: 26px;
    //}
  }
}

/* ------------------------------- error-snack-bar ------------------------------- */

.error-snack-bar {
  .mdc-snackbar__surface {
    //background-color: $redSoft !important;
  }

  .snack-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    p, a {
      font-family: "Work-Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;

      color: $white;
    }

    //a {
    //  text-decoration: underline;
    //  margin-left: auto;
    //}
  }

  .snack-bar__close {
    cursor: pointer;
  }
}

/* ------------------------------- success-snack-bar ------------------------------- */

.success-snack-bar {
  .mdc-snackbar__surface {
    //background-color: $greenLight !important;
  }

  .snack-bar {
    display: flex;
    flex-direction: row;
    gap: 15px;

    //p, a {
    //  font-family: "Plus Jakarta Sans", sans-serif;
    //  font-size: 16px;
    //  font-style: normal;
    //  font-weight: 500;
    //  line-height: 26px;
    //
    //  color: $greenStrong;
    //}
    //
    //a {
    //  text-decoration: underline;
    //  margin-left: auto;
    //}
  }
}
